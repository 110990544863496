import React, { useCallback, useEffect, useRef, useState } from 'react'
import goldenCoin from '../../../assets/pictures/coins/golden/coin.svg'
import silverCoin from '../../../assets/pictures/coins/silver/coin.svg'
import axiosDB from '../../../utils/axios/axiosConfig'

const tg = window.Telegram.WebApp

// Utility functions for calculations
const calculateEnergySpent = (boostActive, energyReduction, touches) =>
	boostActive ? 0 : energyReduction * touches

const calculateCoinsToAdd = (boostActive, energyReduction, touches) =>
	energyReduction * touches * (boostActive ? 10 : 1)

const TapZone = ({
	telegramId,
	currentEnergy,
	setCurrentEnergy,
	energyReduction,
	stage,
	boostData,
	currentCoins,
	setCurrentCoins,
	updateUserData,
	setClaimBalance,
	claimBalance,
}) => {
	const tapTimeout = useRef(null)
	const latestCoins = useRef(currentCoins)
	const pendingTaps = useRef(0)
	const claimBalanceRef = useRef(claimBalance) // ref для claimBalance
	const [taps, setTaps] = useState([])

	// Обновляем ref для claimBalance при изменении
	useEffect(() => {
		claimBalanceRef.current = claimBalance
	}, [claimBalance])

	useEffect(() => {
		latestCoins.current = currentCoins
	}, [currentCoins])

	const debouncedUpdateUserData = useCallback(() => {
		if (tapTimeout.current) {
			clearTimeout(tapTimeout.current)
		}

		tapTimeout.current = setTimeout(async () => {
			if (latestCoins.current >= 1000000) {
				updateUserData()
			}

			let boostActive = isBoostActive()

			if (!boostActive) {
				try {
					const touchesToSend = pendingTaps.current
					const response = await axiosDB.put('/user/update', {
						telegramId,
						touches: touchesToSend,
						claimBalance: claimBalanceRef.current,
					})
					pendingTaps.current = 0
					localStorage.setItem('energy', response.data.energy)
				} catch (error) {
					console.error('Error updating user:', error)
				}
			}
		}, 350)
	}, [telegramId, updateUserData])

	const isBoostActive = useCallback(() => {
		const boostEndTime = new Date(boostData?.dailyBoosts?.[1]?.endTime || 0)
		return boostEndTime > Date.now()
	}, [boostData])

	const handleTouchStart = useCallback(
		e => {
			const touches = e.touches ? e.touches.length : 0
			const boostActive = isBoostActive()

			if (currentEnergy < energyReduction && !boostActive) {
				return
			}

			if (tg.HapticFeedback) {
				tg.HapticFeedback.impactOccurred('light')
			}

			const energySpent = calculateEnergySpent(
				boostActive,
				energyReduction,
				touches
			)
			const newEnergy = Math.max(0, currentEnergy - energySpent)
			setCurrentEnergy(newEnergy)

			const coinsToAdd = calculateCoinsToAdd(
				boostActive,
				energyReduction,
				touches
			)

			setClaimBalance(prevClaimBalance => prevClaimBalance + coinsToAdd)

			const newTaps = Array.from({ length: touches }).map((_, i) => {
				const { clientX, clientY } = e.touches[i]
				const rect = e.target.getBoundingClientRect()
				return {
					id: Date.now() + i,
					x: clientX - rect.left,
					y: clientY - rect.top,
				}
			})

			if (!boostActive) {
				setTaps(prevTaps => [...prevTaps, ...newTaps])

				setTimeout(() => {
					setTaps(prevTaps =>
						prevTaps.filter(
							tap => !newTaps.some(newTap => newTap.id === tap.id)
						)
					)
				}, 1000)

				pendingTaps.current += touches
				debouncedUpdateUserData()
			} else {
				setTaps(prevTaps => [...prevTaps, ...newTaps])

				setTimeout(() => {
					setTaps(prevTaps =>
						prevTaps.filter(
							tap => !newTaps.some(newTap => newTap.id === tap.id)
						)
					)
				}, 1000)
			}
		},
		[
			currentEnergy,
			energyReduction,
			setCurrentEnergy,
			setCurrentCoins,
			debouncedUpdateUserData,
			isBoostActive,
		]
	)

	if (boostData) {
		return (
			<div className='tap-zone' onTouchStart={handleTouchStart}>
				{boostData.upgradeBoosts[4].level === 2 ? (
					<img src='/boosts/skin.svg' alt='skin' />
				) : (
					<img src={stage === 1 ? silverCoin : goldenCoin} alt='coin' />
				)}

				{taps.map(tap => (
					<span
						key={tap.id}
						className='tap_number'
						style={{ top: `${tap.y + 70}px`, left: `${tap.x}px` }}
					>
						+{isBoostActive() ? energyReduction * 10 : energyReduction}
					</span>
				))}
			</div>
		)
	}

	return null
}

export default TapZone
