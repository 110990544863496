// ProgressBar.js
import React from 'react'

const ProgressBar = ({ value }) => {
	// Ограничиваем значение от 0 до 19
	const clampedValue = Math.max(0, Math.min(value, 19))
	const percentage = (clampedValue / 19) * 100

	const containerStyles = {
		height: '100%',
		width: '100%',
		backgroundColor: '#e0e0e0',
		borderRadius: '5px',
		overflow: 'hidden',
		position: 'relative',
	}

	const fillerStyles = {
		height: '100%',
		width: `${percentage}%`,
		background: 'linear-gradient(45deg, #ffa500, #ffd700)',
		transition: 'width 0.3s ease',
		position: 'relative',
	}

	const divisionStyles = {
		position: 'absolute',
		height: '100%',
		width: '1px',
		backgroundColor: 'gray',
		top: '0',
	}

	const divisions = Array.from({ length: 20 }, (_, index) => (
		<div
			key={index}
			style={{
				...divisionStyles,
				left: `${(index / 19) * 100}%`,
			}}
		/>
	))

	return (
		<div style={containerStyles}>
			<div style={fillerStyles}></div>
			{divisions}
		</div>
	)
}

export default ProgressBar
