import React, { useEffect } from 'react'
import './Notification.css'
const Notification = ({
	message,
	setIsNotificationShow,
	setNotificationMessage,
}) => {
	useEffect(() => {
		if (message !== null) {
			setIsNotificationShow(true)
			const timer = setTimeout(() => {
				setIsNotificationShow(false)
				setNotificationMessage(null)
			}, 3000)

			return () => clearTimeout(timer)
		}
	}, [message])

	return (
		<div className='notification'>
			<span>{message}</span>
		</div>
	)
}

export default Notification
