import React, { useEffect } from 'react'
import axiosDB from '../../../../utils/axios/axiosConfig'
import './Claim.css'
function Claim({ claimBalance, telegramId, coins, setCoins, setClaimBalance }) {
	const [currentBalance, setCurrentBalance] = React.useState(0)

	useEffect(() => {
		setCurrentBalance(claimBalance)
	}, [claimBalance])

	const fetchClaim = async () => {
		const response = await axiosDB.put(`/user/updateBalance`, {
			telegramId,
			currentBalance,
		})

		if (response.data.success) {
			setCoins(coins + currentBalance)
			setClaimBalance(0)
			setCurrentBalance(0)
		}
	}
	return (
		<div>
			<button
				onClick={currentBalance >= 1000 ? fetchClaim : () => false}
				className={`${
					currentBalance >= 1000
						? 'claim-balance-btn'
						: 'claim-balance-btn disabled'
				}`}
			>
				<p>{currentBalance}</p>claim
			</button>
		</div>
	)
}

export default Claim
